<template>
  <div class="modal-works">
    <div class="modal-box">
      <div class="close-btn">
        <v-btn icon @click="close()">
          <v-icon x-large>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="modal-content">
        <h1 class="modal-title">{{ fromDB.title }}</h1>
        <v-img v-if="fromDB.movie == ''" :src="fromDB.img" width="100%"></v-img>
        <youtube
          v-if="fromDB.movie != ''"
          :video-id="fromDB.movie"
          ref="youtube"
          width="100%"
        ></youtube>
        <br />
        <div class="modal-text">
          <h3>{{ fromDB.year }}年 {{ fromDB.type }}</h3>
          <br />
          <p>{{ fromDB.explanation }}</p>
        </div>
        <br />
        <v-btn
          v-if="fromDB.link != ''"
          rounded
          width="100px"
          color="#ed5485"
          style="font-weight: bold"
          class="white--text"
          elevation="1"
          :href="fromDB.link"
        >
          <v-icon small> mdi-link-variant </v-icon>
          リンク
        </v-btn>
        <br />
        <ul style="float: right" class="langs">
          <li v-for="lang in fromDB.lang" :key="lang">#{{ lang }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueYoutube from "vue-youtube";
Vue.use(VueYoutube);

export default {
  name: "modal-works",
  props: {},
  data() {
    return {};
  },
  created() {
    this.lang_split();
  },
  methods: {
    close() {
      this.$store.commit("modalOpenWorks", false);
    },
    lang_split() {
      const langs = this.$store.state.works.lang;
      console.log(langs);
    },
  },
  computed: {
    fromDB() {
      return this.$store.state.works;
    },
  },
};
</script>

<style>
.modal-works {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  max-height: 90%;
  max-width: 700px;
  background-color: #fffbcb;
  border-radius: 20px;
  transform: translate(-50%, -50%);
  overflow: auto;
  overflow: scroll;
}

.modal-box .close-btn {
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px 0 0;
  position: sticky;
  top: 0;
}

.modal-box .modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 12% 8%;
  overflow-wrap: break-word;
}

.modal-box .modal-content .modal-title {
  max-width: 100%;
}

.modal-box .modal-content .modal-text {
  width: 100%;
}

.modal-content .langs {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  /* text-align: right; */
}

.modal-content .langs li {
  display: inline-block;
  margin: 0 0.1em 0.6em 0;
  padding: 0.6em;
  font-size: 12px;
  line-height: 1;
  color: #ed5485;
  border: 1px solid #ed5485;
  border-radius: 2em;
  margin-right: 10px;
}
</style>
