<template>
  <div class="research" @click="close()">
    <h1>RESEARCH</h1>
    <v-card elevation="0" width="70%">
      <v-tabs
        height="40"
        background-color="#FFFBCB"
        color="basil"
        v-model="tabs"
      >
        <v-tab>すべて</v-tab>
        <v-tab>主著</v-tab>
        <v-tab>共著</v-tab>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-tabs>
    </v-card>

    <v-tabs-items v-model="tabs">
      <v-tab-item class="tab_content">
        <div v-for="y in years" :key="y">
          <h2 class="mr-auto research_year">{{ y }}</h2>
          <v-row v-for="rsc in reverse_rsc" :key="rsc.index">
            <a v-if="rsc.year == y" class="card-container" @click="open(rsc)">
              <v-img
                :src="rsc.topImage"
                max-height="200"
                max-width="300"
                class="rounded-r-0"
              ></v-img>
              <div class="hover-text"><p class="readmore">Read more</p></div>
              <div class="card-text">
                <h2>{{ rsc.title }}</h2>
                <h4>{{ rsc.authors }}</h4>
                <p>{{ rsc.simpleExplanation }}</p>
              </div>
            </a>
            <Modal v-if="$store.state.isOpen"></Modal>
          </v-row>
        </div>
      </v-tab-item>

      <v-tab-item class="tab_content">
        <div v-for="y in years" :key="y">
          <h2 class="mr-auto research_year">{{ y }}</h2>
          <v-row v-for="rsc in reverse_rsc" :key="rsc.index">
            <a
              v-if="rsc.year == y && rsc.isMain == true"
              class="card-container"
              @click="open(rsc)"
            >
              <v-img
                :src="rsc.topImage"
                max-height="200"
                max-width="300"
                class="rounded-r-0"
              ></v-img>
              <div class="hover-text"><p class="readmore">Read more</p></div>
              <div class="card-text">
                <h2>{{ rsc.title }}</h2>
                <h4>{{ rsc.authors }}</h4>
                <p>{{ rsc.simpleExplanation }}</p>
              </div>
            </a>
            <Modal v-if="$store.state.isOpen"></Modal>
          </v-row>
        </div>
      </v-tab-item>

      <v-tab-item class="tab_content">
        <div v-for="y in years" :key="y">
          <h2 class="mr-auto research_year">{{ y }}</h2>
          <v-row v-for="rsc in reverse_rsc" :key="rsc.index">
            <a
              v-if="rsc.year == y && rsc.isMain == false"
              class="card-container"
              @click="open(rsc)"
            >
              <v-img
                :src="rsc.topImage"
                max-height="200"
                max-width="300"
                class="rounded-r-0"
              ></v-img>
              <div class="hover-text"><p class="readmore">Read more</p></div>
              <div class="card-text">
                <h2>{{ rsc.title }}</h2>
                <h4>{{ rsc.authors }}</h4>
                <p>{{ rsc.simpleExplanation }}</p>
              </div>
            </a>
            <Modal v-if="$store.state.isOpen"></Modal>
          </v-row>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Modal from "@/components/Modal_research.vue";

export default {
  name: "research",
  data() {
    return {
      research: [], //取り出したデータを格納する配列を適当に一個作る
      years: [],
      tabs: null,
    };
  },
  created() {
    this.get_db();
    this.year_array();
  },
  components: {
    Modal,
  },
  computed: {
    rsc_year_divided() {
      //dbの中から、2020年のものだけを抽出してる。使わなかったけど。例えば上で{{rsc_year_divided[0].title}}って書くとdbの一番初めの2020年のもののタイトルが表示される。
      return this.research.filter((rsc) => rsc.year === "2020");
    },
    reverse_rsc() {
      //dbからとってきたデータを格納した配列を、逆順にして出力。一番上が新しいものになるようにしたかったので。
      return this.research.slice().reverse();
    },
  },
  methods: {
    get_db() {
      this.$axios
        .get("https://nakagawa.nkmr.io/api/homepage_research_get.php", {})
        .then((response) => {
          this.research = response.data; //↑で作った配列にデータを入れる
          console.log(this.research);
        })
        .catch((error) => {
          alert("エラーが発生しました");
          console.log("err:", error); //上手く送信できなかったらエラーはく
        });
    },
    year_array() {
      //2020~現在までの年を配列(years[])に格納
      const year_now = new Date().getFullYear();
      for (let i = year_now; i > 2019; i--) {
        this.years.push(i);
      }
    },
    open(array) {
      this.$store.commit("modalOpen", true);
      this.$store.commit("setPaperInfo", array);
    },
  },
};
</script>

<style>
.research {
  background-color: #ffe869;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 85%;
  padding-bottom: 50px;
}

.research h1 {
  margin: 30px 0 40px;
  color: #3B3838;
}

.card-container {
  display: flex;
  width: 800px;
  height: 200px;
  margin: 20px auto;
  background: #ed5485;
  box-shadow: 0 12px 10px -6px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.card-container .readmore {
  font-size: 270%;
  font-weight: bold;
  text-align: center;
  color: rgb(255, 255, 255);
}

.card-container .hover-text {
  width: 800px;
  height: 200px;
  position: absolute;
  top: 1;
  left: 1;
  opacity: 0;
  background-color: rgba(237, 84, 133, 0.8);
  border-radius: 20px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.card-container .hover-text:hover {
  opacity: 0.9;
  padding-top: 70px;
}

.rounded-r-0 {
  border-radius: 20px;
}

.card-text {
  margin: 20px 30px;
  color: rgb(255, 255, 255);
  overflow: hidden;
}

.card-text h2 {
  font-size: 21px;
}

.card-text h4 {
  font-size: 13px;
  margin-top: 6px;
}

.card-text p {
  font-size: 12px;
  margin-top: 10px;
}

.research_year {
  border-bottom: 3px dashed #000;
  padding-top: 30px;
}

.tab_content {
  background-color: #ffe869;
  padding: 0px 15px 15px;
}
</style>