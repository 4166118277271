<template>
  <div class="modal-research">
    <div class="modal-box">
      <div class="close-btn">
        <v-btn icon @click="close()">
          <v-icon x-large>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="modal-content">
        <h1 class="modal-title">{{ paper.title }}</h1>
        <v-img v-if="paper.movie == ''" :src="paper.img" width="100%"></v-img>
        <youtube
          v-if="paper.movie != ''"
          :video-id="paper.movie"
          ref="youtube"
          width="100%"
        ></youtube>
        <br />
        <div class="modal-text">
          <h3>{{ paper.year }}年 {{ paper.conf }} ({{ paper.grade }})</h3>
          <h3>著者</h3>
          <p>{{ paper.authors }}</p>
          <h3>概要</h3>
          <p>{{ paper.abstract }}</p>
        </div>
        <br />
        <v-btn
          rounded
          width="180px"
          color="#ed5485"
          style="font-weight: bold"
          class="white--text"
          elevation="4"
          large
          :href="paper.link"
        >
          詳しい論文情報へ
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueYoutube from "vue-youtube";
Vue.use(VueYoutube);

export default {
  name: "modal-research",
  props: {},
  data() {
    return {
      //   videoId: "Nq7dLw64OkE",
    };
  },
  created() {},
  methods: {
    close() {
      this.$store.commit("modalOpen", false);
    },
    move_link() {},
  },
  computed: {
    paper() {
      return this.$store.state.paper;
    },
  },
};
</script>

<style>
.modal-research {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  max-height: 90%;
  max-width: 800px;
  background-color: #fffbcb;
  border-radius: 20px;
  transform: translate(-50%, -50%);
  overflow: auto;
  overflow: scroll;
}

.modal-box .close-btn {
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px 0 0;
  position: sticky;
  top: 0;
}

.modal-box .modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 12% 8%;
  overflow-wrap: break-word;
}

.modal-box .modal-content .modal-title {
  max-width: 100%;
}

.modal-box .modal-content .modal-text {
  width: 100%;
}
</style>
