<template>
  <div class="profile">
    <h1 class="midasi">PROFILE</h1>
    <div class="main-intro">
      <img class="img" src="../assets/snowman.svg" />
      <div class="self-intro">
        <h2>Name</h2>
        <p>中川由貴</p>
        <h2>Age</h2>
        <p>{{ age }}歳</p>
        <h2>Affiliation</h2>
        <p>明治大学 総合数理学部 先端メディアサイエンス学科</p>
        <h2>Skill</h2>
        <p>Processing, Python, HTML, CSS, JavaScript, Vue.js</p>
      </div>
    </div>
    <p class="introduction">{{introduction}}</p>
  </div>
</template>

<script>
// import moment from "moment";

export default {
  data() {
    return {
      age: "22",
      birthday: "2000-01-08",
      introduction: "2000年 静岡県生まれ。大学からプログラミングを学び始めた。趣味はドラマを見ることや美術館に行くこと。特技はライフル射撃と高速まばたき。犬が好き。小さい頃から手先が比較的器用。",
    };
  },
  created() {
    this.get_age()
  },
  methods: {
    get_age() {
      let d = new Date();
      let year = d.getFullYear();
      let month = d.getMonth() + 1;
      let day = d.getDate();
      if (month==1 && day<8) {
        this.age = year-2001;
      }
      else {
        this.age = year-2000;
      }
    }
  },
};
</script>

<style>
.main-nav a {
  color: #272626;
}

.profile {
  background-color: #ffe869;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 85%;
  padding-bottom: 40px;
}

.profile h1 {
  margin-top: 30px;
  color: #3B3838;
}

.main-intro {
  display: flex;
}

.main-intro .img {
  margin: -50px -60px 0px;
}

.self-intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.self-intro h2 {
  position: relative;
  padding: 7px 20px 7px;
  background: #ed5485;
  color: white;
  font-size: 20px;
  font-weight: 500;
}

.self-intro h2:after {
  position: absolute;
  top: -6px;
  left: -6px;
  width: 100%;
  height: 100%;
  content: "";
  border: 3px solid #000;
}

.self-intro p {
  margin-top: 10px;
  padding: 0 0 0 20px;
}

.profile .introduction {
  width: 570px;
  font-size: 13px;
}
</style>
