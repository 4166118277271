<template>
  <div class="works">
    <h1>WORKS</h1>
    <v-container>
      <v-row class="row">
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          v-for="work in reverse_works"
          :key="work.index"
        >
          <div class="cards">
            <v-card
              @click="open(work)"
              elevation="2"
              width="300px"
              height="400px"
              color="#FFFBCB"
              link
              :ripple="{ center: false, class: 'accent-1' }"
            >
              <v-img
                height="200px"
                :src="work.top_image"
                ><div class="hover-text">
                  <p class="readmore">Read more</p>
                </div>
              </v-img>
              <v-card-title class="font-weight-bold text-h6">{{ work.title }}</v-card-title>

              <v-card-text> {{ work.abstract }} </v-card-text>
              <v-card-subtitle class="text-bottom"
                >{{ work.year }} ({{ work.grade }}/{{
                  work.type
                }})</v-card-subtitle
              >
            </v-card>
          </div>
          <Modal v-if="$store.state.isOpenWorks"></Modal>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Modal from "@/components/Modal_works.vue";

export default {
  name: "works",
  data() {
    return {
      works: [], //取り出したデータを格納する配列を適当に一個作る
    };
  },
  components: {
    Modal,
  },
  created() {
    this.get_db();
  },
  computed: {
    reverse_works() {
      //dbからとってきたデータを格納した配列を、逆順にして出力。一番上が新しいものになるようにしたかったので。
      return this.works.slice().reverse();
    },
  },
  methods: {
    get_db() {
      this.$axios
        .get("https://nakagawa.nkmr.io/api/homepage_works_get.php", {})
        .then((response) => {
          this.works = response.data; //↑で作った配列にデータを入れる
          console.log(this.works);
        })
        .catch((error) => {
          alert("エラーが発生しました");
          console.log("err:", error); //上手く送信できなかったらエラーはく
        });
    },
    open(array) {
      this.$store.commit("modalOpenWorks", true);
      this.$store.commit("setWorksInfo", array);
    },
  },
};
</script>

<style>
.works {
  background-color: #ffe869;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 85%;
  padding-bottom: 50px;
}

.works h1 {
  margin: 30px 0 40px;
  color: #3B3838;
}

.cards {
  display: flex;
  justify-content: center;
}

.cards .readmore {
  font-size: 270%;
  font-weight: bold;
  text-align: center;
  color: rgb(255, 255, 255);
}

.cards .hover-text {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 1;
  left: 1;
  opacity: 0;
  background-color: rgba(237, 84, 133, 0.8);
  border-radius: 4px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 2;
}

.cards .hover-text:hover {
  opacity: 0.9;
  padding-top: 70px;
}

.cards .text-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;
}


</style>